import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Youtube from "../../../assets/youtube.svg";
import "./Post.scss";

export default function Post({
  post,
  hideDescription = false,
  className = "",
}) {
  const { title, description, image, url, video: videoLink } = post;

  return (
    <article className={`post ${className}`}>
      {image && (
        <Link to={`/${url}/`} className="image-wrapper">
          {videoLink && <Youtube className="tag-video" />}

          <GatsbyImage image={getImage(image)} alt={title} />
        </Link>
      )}

      <div className="content">
        <h3 className="title">
          <Link className="truncate" to={`/${url}/`}>
            {title}
          </Link>
        </h3>
        {!hideDescription && (
          <span className="summary truncate">{description}</span>
        )}
      </div>
    </article>
  );
}
