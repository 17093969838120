import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import VideoItem from "./VideoItem";
import "./VideoList.scss";

export default function VideoList() {
  const {
    allYoutubeVideo: { playlist },
  } = useStaticQuery(graphql`
    {
      allYoutubeVideo(limit: 4, sort: { fields: publishedAt, order: DESC }) {
        playlist: nodes {
          localThumbnail {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1.78
                width: 380
              )
            }
          }
          title
          videoId
          publishedAt(formatString: "MMM DD, YYYY")
        }
      }
    }
  `);

  return (
    <div className="video-container">
      {playlist.map((video) => (
        <VideoItem video={video} key={video.videoId} />
      ))}
    </div>
  );
}
