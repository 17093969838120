import React, { useContext, useEffect } from "react";
import Layout from "component/Layout/Layout";
import Home from "component/Home/Home";
import { ArticlesContext } from "context/Articles";
import Seo from "component/seo";
import SidebarRight from "component/Sidebar/SidebarRight";
import SidebarLeft from "component/Sidebar/SidebarLeft";
import { useSiteUrl } from "utils";

export default function HomePage({ location }) {
  const { posts } = useContext(ArticlesContext);
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [posts]);

  return (
    <Layout>
      <Seo canonicalUrl={canonicalUrl} />
      <SidebarLeft />
      <Home posts={posts} />
      <SidebarRight />
    </Layout>
  );
}
