import { css } from "@emotion/react";
import { Link } from "gatsby";
import React from "react";
import Post from "../Post/Post";
import VideoList from "../VideoList/VideoList";
import "./Home.scss";

export default function Home({ posts }) {
  return (
    <main className="main flex-grow-1">
      <div className="homepage">
        {posts && (
          <>
            <section
              css={css`
                margin-bottom: 2rem;
              `}
            >
              <h2 className="title line">Bài viết gần đây</h2>
              <div className="posts">
                {posts.map((post) => (
                  <Post key={post.id} post={post} />
                ))}
              </div>

              <div className="button">
                <Link to="/articles/">Xem thêm</Link>
              </div>
            </section>

            <section>
              <h2 className="title line">Video</h2>
              <div className="video-section">
                <span
                  css={css`
                    margin-bottom: 1rem;
                    display: inline-block;
                  `}
                >
                  Video từ channel&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                    href="https://www.youtube.com/c/Homiedev"
                  >
                    Homiedev
                  </a>
                </span>

                <VideoList />

                <div className="button">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="view-more"
                    href="https://www.youtube.com/c/Homiedev"
                  >
                    Xem Thêm
                  </a>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </main>
  );
}
