import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
export default function VideoItem({ video }) {
  const { localThumbnail: image, title, videoId } = video;

  return (
    <div className="video">
      <div className="video__thumbnail">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.youtube.com/watch?v=${videoId}`}
        >
          <GatsbyImage image={getImage(image)} alt={title} />
        </a>
      </div>
      <div className="video__info">
        <h3 className="title">
          <a
            target="_blank"
            rel="noreferrer"
            className="truncate"
            title={title}
            href={`https://www.youtube.com/watch?v=${videoId}`}
          >
            {title}
          </a>
        </h3>
      </div>
    </div>
  );
}
